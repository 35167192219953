import firebase from "firebase";
import "firebase/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBpMtQQL4XtMxMOeoQJh6a8Badyheqdrvc",
  authDomain: "portfolio-f7234.firebaseapp.com",
  databaseURL: "https://portfolio-f7234.firebaseio.com",
  projectId: "portfolio-f7234",
  storageBucket: "portfolio-f7234.appspot.com",
  messagingSenderId: "1034431199036",
  appId: "1:1034431199036:web:6e265148ff2b7632e51039",
  measurementId: "G-KXYMJ58YNV"
});

 
const db = firebase.firestore();
const auth = app.auth();

export { db, auth }
export default app;